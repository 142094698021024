import { Link } from "react-router-dom";
import { FaCopyright } from "react-icons/fa";

import classes from "./Footer.module.css";
import { companyName, address } from "../../util/config";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={classes.footer}>
      <div className={classes.content}>
        <div className={classes.disclosure}>
          <h2>Important Disclosures</h2>
          <p>
            Investing in cryptocurrency carries inherent risks. While{" "}
            {companyName} employs advanced trading strategies and expert market
            analysis to maximize returns, the cryptocurrency market is highly
            volatile and unpredictable. Past performance does not guarantee
            future results. We encourage all clients to invest responsibly and
            only commit funds that they can afford to lose. While our tailored
            investment plans aim to align with your risk tolerance and goals,
            fluctuations in the market can impact returns. {companyName} is
            committed to transparency and accountability. We strive to minimize
            risks through careful portfolio management and by keeping you
            informed about your investments. However, as with any investment,
            there is no guarantee of profit, and losses are possible. By
            engaging with our services, you acknowledge and accept the risks
            involved in cryptocurrency investment and agree that {companyName}{" "}
            is not liable for losses arising from market movements. For further
            guidance, feel free to consult our support team or a professional
            financial advisor before making any investment decisions.
          </p>
        </div>
        <nav className={classes.nav}>
          <div className={classes.navItem}>
            <h2>INFORMATION</h2>
            <div className={classes.navList}>
              <Link to="/">Home</Link>
              <Link to="/about-us">About us</Link>
              <Link to="/why-us">Why us</Link>
              <Link to="/terms">Terms of service</Link>
              <Link to="/security">Security</Link>
              <Link to="/contact-us">Contact us</Link>
            </div>
          </div>
          <div className={classes.navItem}>
            <h2>LEGAL</h2>
            <div className={classes.navList}>
              <Link to="/cookies-policy">Cookies policy</Link>
              <Link to="/privacy-policy">Privacy policy</Link>
            </div>
          </div>
          <div className={classes.navItem}>
            <h2>LEARN MORE</h2>
            <div className={classes.navList}>
              <Link to="/avoid-scam">Avoid fraud</Link>
            </div>
          </div>
        </nav>
      </div>
      <div className={classes.addressContainer}>
        <span className={classes.addressTitle}>Address:</span>
        <span className={classes.address}>{address}</span>
      </div>
      <div className={classes.copyright}>
        <FaCopyright className={classes.icon} />
        <span>
          {year} {companyName}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
