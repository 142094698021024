import { Outlet } from "react-router-dom";

import Nav from "../dashboard/Nav";

const DashboardLayout = () => {
  return (
    <section className="dashboard">
      <Nav />
      <main>
        <Outlet />
      </main>
    </section>
  );
};

export default DashboardLayout;
