import { Helmet } from "react-helmet-async";

import classes from "./About.module.css";
import { companyName, members } from "../../util/config";

const Profile = ({ photo, name, role }) => {
  return (
    <div className={classes.profile}>
      <div className={classes.photo}>
        <img src={photo} alt={name} />
      </div>
      <span>{name}</span>
      <span>{role}</span>
    </div>
  );
};

const About = () => {
  return (
    <section className={classes.about}>
      <Helmet>
        <title>ABOUT US</title>
        <meta name="description" content={`About ${companyName}`} />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <p className={classes.mission}>
        Our mission is to look after the financial best interests of our clients
        making sure they experience total financial freedom in their life time
        with minimal risk and zero stress.
      </p>
      <div className={classes.content}>
        <h2>MORE ABOUT US</h2>
        <div className={classes.textContainer}>
          <p>
            At {companyName}, we are redefining how individuals engage with
            cryptocurrency investments. Founded on a passion for innovation and
            a deep understanding of the crypto market, we aim to make digital
            asset trading accessible and profitable for everyone. Our mission is
            to bridge the gap between complex crypto trading and everyday
            investors by offering expertly managed investment plans. Whether
            you're a seasoned investor or new to the world of crypto, we empower
            you to achieve financial growth with confidence and ease.
          </p>
          <p>
            Our company operates on the principles of trust, transparency, and
            performance. We believe that crypto investments should not be a
            gamble but a calculated opportunity to grow wealth. With a team of
            experienced traders and cutting-edge tools, we manage the risks of
            the volatile crypto market, ensuring that your portfolio remains
            optimized for maximum returns. At {companyName}, we don't just
            trade—we strategize, monitor, and adjust in real-time, so your
            investments work harder for you.
          </p>
          <p>
            One of the core values that sets us apart is our commitment to
            personalization. We understand that every investor has unique goals,
            risk tolerances, and expectations. That's why we've designed a range
            of flexible subscription plans to cater to diverse needs. Whether
            you're looking for steady, consistent growth or high-yield
            opportunities, our plans are tailored to match your financial
            aspirations. At every step of your journey with us, you'll
            experience unmatched customer support and a platform designed for
            simplicity and effectiveness.
          </p>
          <p>
            As a company, we are dedicated to security and reliability.
            Cryptocurrency is a fast-evolving space, and we pride ourselves on
            staying ahead of the curve to protect your investments. From robust
            encryption to secure wallets, every transaction and investment is
            safeguarded against potential risks. Our commitment is to deliver
            results while upholding the highest standards of professionalism. At
            {companyName}, we're not just managing investments—we're building
            lasting relationships with our clients, one successful trade at a
            time.
          </p>
        </div>
      </div>
      <div className={classes.members}>
        <h2>We're dedicated humans committed to growing wealth for anyone</h2>
        <div className={classes.profiles}>
          {members.map((el) => (
            <Profile
              name={el.name.toUpperCase()}
              role={el.role}
              photo={el.photo}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
