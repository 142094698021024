import { useState } from "react";
import useInput from "../../hooks/userInput";
import { useNavigate } from "react-router-dom";
import { GoNumber } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import classes from "./VerifyLogin.module.css";
import { signIn } from "../../api/api";
import { authActions } from "../../store/authSlice";
import AuthAlert from "../alerts/AuthAlert";
import Spinner from "../UI/Spinner";

const VerifyLogin = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setCookie = useCookies(["jwt"])[1];

  const {
    value: tokenInput,
    enteredValueIsValid: tokenInputIsValid,
    hasError: tokenInputIsInvalid,
    valueInputChangedHandler: tokenInputChangedHandler,
    valueInputBlurHandler: tokenInputBlurHandler,
    reset: tokenInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;

  if (tokenInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const res = await signIn({ token: tokenInput });

    if (res.status === "success") {
      setCookie("jwt", res.token);
      dispatch(authActions.login({ user: res.data.user }));
      setAlertMsg(res.message);
      setAlertStatus(true);
      setShowAlert(true);
      setTimeout(() => {
        navigate("/dashboard", { replace: true });
      }, 1500);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }

    tokenInputReset();
    setTimeout(() => {
      setShowAlert(false);
      setShowSpinner(false);
    }, 3000);
  };

  const tokenInputClasses = tokenInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  return (
    <section className={classes.container}>
      <form className={classes.form} onSubmit={submitHandler}>
        {showAlert && <AuthAlert message={alertMsg} status={alertStatus} />}
        {showSpinner && <Spinner />}
        <div className={tokenInputClasses}>
          <label htmlFor="email">6 digit access token sent to your email</label>
          <div className={classes["input-group"]}>
            <GoNumber className={classes.icon} />
            <input
              type="number"
              value={tokenInput}
              onChange={tokenInputChangedHandler}
              onBlur={tokenInputBlurHandler}
            />
          </div>
        </div>
        <div className={classes.action}>
          <button type="submit" disabled={!formIsValid}>
            Login
          </button>
        </div>
      </form>
    </section>
  );
};

export default VerifyLogin;
