import { useState } from "react";
import { useCookies } from "react-cookie";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaRegFlag, FaRegCalendar } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa6";
import { FcClock } from "react-icons/fc";
import { CiWallet } from "react-icons/ci";
import useInput from "../../hooks/userInput";

import classes from "./Investment.module.css";
import { formatAmount, formatDate } from "../../util/helpers";
import AuthAlert from "../alerts/AuthAlert";
import Spinner from "../UI/Spinner";
import { withdraw } from "../../api/api";

const Investment = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { jwt } = useCookies(["jwt"])[0];
  const {
    value: walletInput,
    enteredValueIsValid: walletInputIsValid,
    hasError: walletInputIsInvalid,
    valueInputChangedHandler: walletInputChangedHandler,
    valueInputBlurHandler: walletInputBlurHandler,
    reset: walletInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;
  if (walletInputIsValid) {
    formIsValid = true;
  }

  const withdrawHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const res = await withdraw({ wallet: walletInput }, props.id, jwt);
    if (res.status === "success") {
      setAlertMsg(res.message);
      setAlertStatus(true);
      setShowAlert(true);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }

    walletInputReset();
    setTimeout(() => {
      setShowAlert(false);
      setShowSpinner(false);
    }, 6000);
  };

  const walletInputClasses = walletInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  return (
    <div className={classes.investment}>
      {showAlert && <AuthAlert message={alertMsg} status={alertStatus} />}
      {showSpinner && <Spinner />}
      <div className={classes.content}>
        <div className={classes.title}>
          <FaFolderOpen className={classes.icon} />
          <span>Portfolio</span>
        </div>
        <span className={classes.value}>{props.name.toUpperCase()}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span>Invested</span>
        </div>
        <span className={classes.value}>
          ${formatAmount(props.investedAmount)}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span>Total returns</span>
        </div>
        <span className={classes.value}>${formatAmount(props.returns)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <MdOutlineAttachMoney className={classes.icon} />
          <span>Weekly returns</span>
        </div>
        <span className={classes.value}>
          ${formatAmount(props.weeklyReturns)}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FcClock className={classes.icon} />
          <span>Contract length</span>
        </div>
        <span className={classes.value}>{props.contractLength} Months</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>Date Invested</span>
        </div>
        <span className={classes.value}>{formatDate(props.createdAt)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>Start date</span>
        </div>
        <span className={classes.value}>
          {formatDate(props.startDate) || "N/A"}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>End date</span>
        </div>
        <span className={classes.value}>
          {formatDate(props.endDate) || "N/A"}
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegCalendar className={classes.icon} />
          <span>Last updated</span>
        </div>
        <span className={classes.value}>{formatDate(props.updatedAt)}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <FaRegFlag className={classes.icon} />
          <span>Status</span>
        </div>
        <span className={classes.value}>
          {props.status === "created"
            ? "PROCESSING"
            : props.status.toUpperCase()}
        </span>
      </div>
      {props.withdrawStatus && (
        <div className={classes.content}>
          <div className={classes.title}>
            <FaRegFlag className={classes.icon} />
            <span>Withdraw Status</span>
          </div>
          <span className={classes.value}>
            {props.withdrawStatus?.toUpperCase()}
          </span>
        </div>
      )}
      {props.status === "matured" && !props.withdrawStatus && (
        <form className={classes.form} onSubmit={withdrawHandler}>
          <div className={walletInputClasses}>
            <label>Your BTC wallet address</label>
            <div className={classes["input-group"]}>
              <CiWallet className={classes.icon} />
              <input
                type="text"
                value={walletInput}
                onChange={walletInputChangedHandler}
                onBlur={walletInputBlurHandler}
              />
            </div>
          </div>
          <div className={classes.action}>
            <button type="submit" disabled={!formIsValid}>
              Withdraw
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Investment;
