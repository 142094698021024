import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet-async";

import { getPlans } from "../../api/api";
import classes from "./Plans.module.css";
import PlanItem from "./PlanItem";
import { companyName } from "../../util/config";

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loadingArr = [1, 2, 3];

  useEffect(() => {
    const fetchPlans = async () => {
      const res = await getPlans();
      if (res.status === "success") {
        setPlans(res.data.plans);
        setIsLoading(false);
      }
    };
    fetchPlans();
  }, []);
  return (
    <section className={classes.container}>
      <Helmet>
        <title>PLANS</title>
        <meta
          name="description"
          content={`${companyName}''s investment plans`}
        />
        <link rel="canonical" href="/plans" />
      </Helmet>
      <div className={classes.heading}>
        <h2>Simple, transparent plans</h2>
        <p>
          Rather than surprise fees, we bundle our investments plans into pocket
          friendly tiers that support your financial wellness.
        </p>
      </div>
      <div className={classes.plans}>
        {isLoading &&
          loadingArr.map((el) => (
            <Skeleton
              key={el}
              variant="rectangular"
              width="30rem"
              height="60rem"
            />
          ))}
        {!isLoading &&
          plans.map((el) => (
            <PlanItem
              key={el._id}
              id={el._id}
              name={el.name}
              startRange={el.startRange}
              endRange={el.endRange}
              returns={el.return}
            />
          ))}
      </div>
    </section>
  );
};

export default Plans;
