import { useState } from "react";
import { Link } from "react-router-dom";
import { IoReorderFourOutline, IoClose } from "react-icons/io5";

import classes from "./Header.module.css";
import { companyName } from "../../util/config";
import Nav from "./Nav";
import TradingViewTicker from "../trading-views/TradingViewTicker";

const Header = () => {
  const [open, setOpen] = useState(true);

  const openNav = () => {
    setOpen(true);
  };

  const closeNav = () => {
    setOpen(false);
  };

  return (
    <header className={classes.container}>
      <div className={classes.header}>
        <Link to="/" className={classes.logo}>
          <h2>{companyName}</h2>
        </Link>
        {open && <Nav />}
        {!open && (
          <IoReorderFourOutline className={classes.icon} onClick={openNav} />
        )}
        {open && <IoClose className={classes.icon} onClick={closeNav} />}
      </div>
      <TradingViewTicker />
    </header>
  );
};

export default Header;
