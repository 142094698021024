import classes from "./Partners.module.css";
import sequoia from "../../images/Sequoai.png";
import coinbase from "../../images/coinbase.png";
import commerzVentures from "../../images/commerzventures.png";
import spark from "../../images/sparkcapital.png";
import capitalG from "../../images/capitalG.png";
import kpcb from "../../images/KPCB.png";
import trustWallet from "../../images/Trust_Wallet_Shield_fe03d09499.avif";

const Partners = () => {
  return (
    <section className={classes.partners}>
      <div className={classes.heading}>
        <h2>Our partners</h2>
        <p>We're backed by some of the world's most notable partners.</p>
      </div>
      <div className={classes.content}>
        <div className={classes.images}>
          <img src={sequoia} alt="sequoia" />
        </div>
        <div className={classes.images}>
          <img src={coinbase} alt="coinbase" />
        </div>
        <div className={classes.images}>
          <img src={commerzVentures} alt="commerz ventures" />
        </div>
        <div className={classes.images}>
          <img src={spark} alt="spark" />
        </div>
        <div className={classes.images}>
          <img src={capitalG} alt="capitalG" />
        </div>
        <div className={classes.images}>
          <img src={kpcb} alt="kpcb" />
        </div>
        <div className={classes.images}>
          <img src={trustWallet} alt="trust wallet" />
        </div>
      </div>
    </section>
  );
};

export default Partners;
