import { Link } from "react-router-dom";

import classes from "./Nav.module.css";

const Nav = () => {
  return (
    <nav className={classes.nav}>
      <Link to="/dashboard/investments">INVESTMENTS</Link>
      <Link to="/dashboard/trade-view">TRADE VIEW</Link>
      <Link to="/dashboard/withdraws">WITHDRAWS</Link>
      <Link to="/dashboard/password">PASSWORD</Link>
    </nav>
  );
};

export default Nav;
