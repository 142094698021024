import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import classes from "./PlanItem.module.css";
import { formatAmount } from "../../util/helpers";

const PlanItem = ({ id, name, startRange, endRange, returns }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div className={classes.plan}>
      <h2>{name.toUpperCase()}</h2>
      {endRange < 100000 && (
        <p>
          ${formatAmount(startRange)} - ${formatAmount(endRange)}
        </p>
      )}
      {endRange > 100000 && <p>${formatAmount(startRange)} and ABOVE</p>}
      <p>{returns}% weekly returns</p>
      <p>Enhanced Security</p>
      <p>24/7 Support</p>
      <Link to={!isLoggedIn ? "/create-account" : `/invest/${id}`}>
        Invest Now
      </Link>
    </div>
  );
};

export default PlanItem;
